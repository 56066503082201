<template>
    <div class="container mx-auto">
        <div class="mt-6 md:mt-16 px-6 md:px-16">

            <h1 class="font-bold text-40px uppercase tracking-wider">Export Data Capture</h1>

            <div class="mt-8 max-w-xs">

                <h2 class="font-bold uppercase text-xl tracking-wider">SELECT DATE RANGE</h2>

                <form autocomplete="off" class="font-work-sans" v-on:submit.prevent="exportData" method="post">

                    <VueCtkDateTimePicker v-model="dateRange" :range="true" label="Date Range" format="YYYY/MM/DD HH:mm:ss" formatted="ll" color="#000000" button-color="#000000" :dark="false" :error="dateRangeError" @is-shown="dateRangeError=error=false" @formatted-value="set_formatted_value"></VueCtkDateTimePicker>

                    <div class="mt-8">
                        <button class="bg-white text-black font-dense tracking-wider text-4xl font-bold w-full border-b-3 border-turquoise leading-tight pt-2 outline-none relative" :class="{'cursor-not-allowed':loading}" type="submit">EXPORT <span class="spinner" v-if="loading"><span></span><span></span><span></span><span></span></span></button>
                    </div>

                    <div class="mt-8 text-red-600" v-if="error">
                        {{error_message}}
                    </div>

                </form>

            </div>

        </div>
    </div>
</template>

<script>
    import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
    import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
    import { saveAs } from 'file-saver';

    export default {
        name: 'ExportDataCapture',
        components: {
            VueCtkDateTimePicker
        },
        data: function() {
            return {
                dateRange: null,
                dateRangeError: false,
                error: false,
                error_message: '',
                formatted_value: '',
                loading: false,
            }
        },
        methods: {
            exportData: function() {
                if( this.dateRange !== null && this.dateRange.start !== null && this.dateRange.end !== null ) {
                    var app = this;
                    this.dateRangeError = false;
                    this.error = false;
                    this.loading = true;
                    this.$http({
                        url: 'export-data-capture',
                        method: 'post',
                        data: {
                            ...this.dateRange
                        }
                    })
                        .then(function(response) {
                            app.loading = false;
                            if( response.data.count === 0 ) {
                                app.error = true;
                                app.error_message = 'No records found between choosen date range';
                            } else {
                                saveAs(new Blob([response.data.export_data]), app.formatted_value+'.csv');
                            }
                        })
                        .catch(function(err){
                            app.loading = false;
                            app.error = true;
                            app.error_message = err.response.data.message;
                        });
                } else {
                    this.dateRangeError = true;
                }
            },
            set_formatted_value: function(value) {
                this.formatted_value = value;
            }
        }
    }
</script>

<style scoped>
    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .spinner {
        position: absolute;
        top: 25%;
        left: auto;
        right: 25%;
        transition-property: padding, opacity;
        transition-duration: 0.2s, 0.2s;
        transition-timing-function: ease-in, ease;
        transition-delay: 0s, 0.2s;
    }
    .spinner span {
        box-sizing: border-box;
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0.15rem;
        width: 1.5rem;
        height: 1.5rem;
        opacity: 1;
        border: 3.4px solid #0DE9FF;
        border-radius: 50%;
        animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #0DE9FF transparent transparent transparent;
    }
    .spinner span:nth-child(1) {
        animation-delay: 0.45s;
    }
    .spinner span:nth-child(2) {
        animation-delay: 0.3s;
    }
    .spinner span:nth-child(3) {
        animation-delay: 0.15s;
    }
</style>

<style>
    .header-picker {
        border-bottom: 3px solid #0DE9FF !important;
    }
</style>